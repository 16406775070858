<template>
    <v-app>
        <v-dialog max-width="900" v-model="dialog">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ edit ? 'Update' : 'Add'}} section
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <div class="row">
                        <div class="col-12 form-group">
                            <v-text-field dense label="Section name" outlined
                                          v-model="homepageSection.title"></v-text-field>
                            <span class="text-danger" v-if="$v.homepageSection.title.$error">This information is required</span>
                        </div>


                        <div class="col-12">
                            <label for="">Description</label>
                            <ckeditor :config="editorConfig" v-model="homepageSection.description"></ckeditor>
                            <!-- <span class="text-danger" v-if="$v.page.content.$error">Content is required</span> -->
                        </div>
                        <v-col md="2">
                            <v-switch
                                    label="DB Block"
                                    v-model="homepageSection.is_db_block"
                            >
                            </v-switch>
                        </v-col>
                        <v-col md="4">
                            <v-select
                                    :items="homepageSection.is_db_block ? db_fields : non_db_fields"
                                    dense
                                    item-text="name"
                                    item-value="value"
                                    label="Type"
                                    outlined
                                    v-model="homepageSection.type"
                            >
                            </v-select>
                        </v-col>
                        <v-col md="4">
                            <v-select
                                    :items="[1,2,3,4,5,6]"
                                    dense
                                    label="No of column"
                                    outlined
                                    v-model="homepageSection.no_of_columns"
                            >
                            </v-select>
                        </v-col>
                        <v-col md="2">
                          Removable
                            <v-switch
                                :label="homepageSection.is_removable?'Active':'Inactive'"
                                color="primary"
                                    v-model="homepageSection.is_removable"
                            >
                            </v-switch>
                        </v-col>
                        <div class="col-12 mt-5">
                          Status
                            <v-switch
                                :label="homepageSection.is_active?'Active':'Inactive'"
                                color="primary"
                                    v-model="homepageSection.is_active"
                            >
                            </v-switch>
                        </div>

                    </div>

                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div class="float-right">
                  <v-btn
                      @click="cancelForm"
                      class="text-gray btn btn-standard cancel-btn"
                      depressed
                  >Cancel
                  </v-btn>

                  <v-btn
                      :disabled="sizeExceed"
                      :loading="isBusy"
                      @click="createOrUpdate"
                      class="text-white btn btn-primary"
                      depressed
                  >Save
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>

        </v-dialog>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import HomePageSectionService from "@/core/services/homepage-section/HomePageSectionService";

    const homePageSectionService = new HomePageSectionService();

    export default {
        validations: {
            homepageSection: {
                title: {required}
            }
        },
        data() {
            return {
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                db_fields: [

                    {
                        "name": 'Slider',
                        "value": 'slider'
                    },

                    {
                        "name": 'News ',
                        "value": 'news'
                    },

                    {
                        "name": 'Programs',
                        "value": 'programs'
                    },


                    {
                        "name": 'News,event & exam and result',
                        "value": 'news_event_result'
                    },

                    {
                        "name": 'Gallery',
                        "value": 'gallery'
                    },
                    {
                        "name": 'Video gallery',
                        "value": 'video_gallery'
                    },
                    {
                        "name": 'Testimonials',
                        "value": 'testimonials'
                    },

                    {
                        "name": 'Blogs',
                        "value": 'blogs'
                    },
                ],
                non_db_fields: [

                    {
                        "name": 'Info box',
                        "value": 'info_box'
                    },


                    {
                        "name": 'Banner',
                        "value": 'banner'
                    },

                    {
                        "name": 'Introduction ',
                        "value": 'introduction'
                    },
                    {
                        "name": 'Details list',
                        "value": 'detail_list'
                    },
                    {
                        "name": 'Call to actions ',
                        "value": 'call_to_action'
                    },
                ],
                editorConfig: {
                    versionCheck: false,
                    scayt_autoStartup: true,
                    allowedContent: true,
                    pasteFromWordRemoveFontStyles: true,
                    pasteFromWordRemoveStyles: true,
                    pasteFromWordNumberedHeadingToList: true,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'SpellChecker', 'Scayt', 'PasteFromWord']]

                },
                homepageSection: {
                    title: null,
                    is_removable: true,
                    is_active: true
                }
            };
        },
        methods: {
            cancelForm(){
                this.resetForm()
                this.hideModal()
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getHomePageSection(id)
                }else{
                    this.resetForm()
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            getHomePageSection(id) {
                homePageSectionService.show(id).then(response => {
                    this.homepageSection = response.data.home_page_section
                })
            },
            generateFd() {
                // console.log(this.homepageSection)
                let fd = new FormData();
                for (let key in this.homepageSection) {
                    if (
                        key == "cover_image" &&
                        this.homepageSection["cover_image"] &&
                        this.homepageSection["cover_image"] != null
                    ) {
                        fd.append("cover_image", this.homepageSection[key]);
                    } else if (key == "is_active" && this.homepageSection["is_active"]) {
                        fd.append("is_active", this.homepageSection ? 1 : 0);
                    } else {
                        if (this.homepageSection[key] != null) {
                            fd.append(key, this.homepageSection[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.homepageSection.$touch();
                if (this.$v.homepageSection.$error) {
                    setTimeout(() => {
                        this.$v.homepageSection.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.updateSection(fd);
                    } else {
                        this.createSection(fd);
                    }
                }
            },
            updateSection(fd) {
                this.isBusy = true;
                homePageSectionService
                    .update(this.homepageSection.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.hideModal();
                        this.$emit("refresh_homepageSection");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            createSection(fd) {
                this.isBusy = true;
                homePageSectionService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");
                        this.hideModal();
                        this.$emit("refresh_homepageSection");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.homepageSection.$reset();
                this.edit = false;
                this.homepageSection = {
                    title: null,
                    is_removable: true,
                    is_active: true,
                };
            }
        }
    };
</script>
