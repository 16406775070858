<template>
    <v-app>
        <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-title pt-3 px-3 d-flex justify-content-between">
                    <div class="breadcrumb-left">
                      <h4>
                        Manage home page
                      </h4>
                      <div class="breadcrumb-sub-header">
                        <router-link to="/dashboard">Dashboard </router-link>\ Home page
                      </div>
                    </div>
                    <div class="breadcrumb-right">
                      <v-btn     v-if="checkIsAccessible('album' ,'create')"
                             @click="addSection()"
                             class="mt-4 btn btn-primary  mr-1"
                             dark>
                        <i class="fa fa-plus"></i>
                        Add homepage
                      </v-btn>

                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                    <div class="row">
                      <v-col cols="12" md="5">
                        <v-text-field dense    v-on:keyup.enter="getSections()"
                                      label="Title"
                                      outlined
                                      type="text"
                                      v-model="search.title"/>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-select :items="active_statuses" dense item-text="title" item-value="value"
                                  label="Status"  v-on:keyup.enter="getSections()"
                                  outlined
                                  clearable
                                  v-model="search.active"></v-select>
                      </v-col>

                      <v-col cols="12" md="4" class="text-right">
                        <v-btn
                            @click="getSections()"
                            class="btn btn-primary btn-search w-35"
                            :loading="loading"
                        >
                          <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                          Search
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                  <div class="responsive">
                    <table class="table ">
                      <thead>
                      <tr class="px-3">
                        <th class="px-3 wrap-column">Title</th>
                        <th class="px-3 wrap-column">Action</th>
                      </tr>
                      </thead>
                      <draggable v-show="homepage_sections.length>0" :move="checkMove" class="text-left" handle=".handle" tag="tbody"
                                 v-model="homepage_sections">
                        <tr :key="index" v-for="(home_page_section, index) of homepage_sections">
                          <td scope="row" class="px-3 wrap-column">
                            <i class="cursor-drag fas fa-sort handle pr-2"></i>
                            <router-link :to="{name:'home-page-content',params:{id :home_page_section.id}}">
                              {{
                                home_page_section.title }}
                            </router-link>
                           &nbsp;&nbsp; <i :class="home_page_section.is_active?'dot-active':'dot-inactive'"
                               class="fas fa-circle"></i>
                          </td>

                          <td class="px-3 wrap-column">
                            <b-dropdown
                                no-caret
                                no-flip
                                right
                                size="sm"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                variant="link"
                            >
                              <template v-slot:button-content>
                                <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                              </template>
                              <!--begin::Navigation-->
                              <div class="navi navi-hover ">

                                <b-dropdown-text class="navi-item" tag="div"
                                                 v-if="checkIsAccessible('album' ,'show')">
                                  <a @click="detail(home_page_section.id)" class="navi-link"
                                     href="#">
                                                            <span class="navi-icon">
                                                                 <i class="fas fa-eye"></i>
                                                             </span>
                                    <span class="navi-text"> View </span>
                                  </a>
                                </b-dropdown-text>
                                <b-dropdown-text class="navi-item" tag="div"
                                                 v-if="checkIsAccessible('album' ,'edit')">
                                  <a @click="editSection(home_page_section.id)" class="navi-link"
                                     href="#">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                    <span class="navi-text"> Edit </span>
                                  </a>
                                </b-dropdown-text>

                                <b-dropdown-text class="navi-item" tag="div"
                                                 v-if="checkIsAccessible('album' ,'delete')">
                                  <a @click="deleteSection(home_page_section.id)"
                                     class="navi-link"
                                     href="#">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                    <span class="navi-text">  Delete</span>
                                  </a>
                                </b-dropdown-text>

                              </div>
                              <!--end::Navigation-->
                            </b-dropdown>

                          </td>
                        </tr>
                      </draggable>
                      <tr v-if="homepage_sections.length == 0">
                        <td colspan="2" class="text-center">
                          No data available to display..
                        </td>
                      </tr>
                    </table>
                    <b-pagination
                        v-if="homepage_sections.length > 0"
                        class="pull-right mt-7"
                        :per-page="perPage"
                        :total-rows="total"
                        @input="getSections"
                        first-number
                        last-number
                        v-model="page"
                    ></b-pagination>
                  </div>

                </div>
                <create-and-update @refresh_homepageSection="getSections" ref="album"></create-and-update>
              </div>
            </div>
        </div>

    </v-app>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import CreateAndUpdate from "./CreateAndUpdate";
    import draggable from 'vuedraggable'
    import HomePageSectionService from "@/core/services/homepage-section/HomePageSectionService";

    const homePageSectionService = new HomePageSectionService();
    export default {
        name: "homepage-sectopm",
        display: "Table",
        order: 8,
        components: {
            CreateAndUpdate,
            draggable
        },
        data() {
            return {
                homepage_sections: [],
                page: null,
                perPage: null,
                total: null,
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'Inactive', value: "in_active"}
                ],
                search: {
                    active: "active",
                },
                dragging: false,
              loading: false
            };
        },
        mounted() {
            this.getSections();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Album", route: "wizard-1"},
                {title: "Album"}
            ]);
        },
        methods: {
            getSections() {
              this.loading=true;
                homePageSectionService
                    .paginate(this.search, this.page)
                    .then(response => {
                        this.homepage_sections = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading=false;
                    })
                    .catch(error => {
                      this.loading=false;
                        // console.log(error);
                    });
            },
            addSection() {
                this.$refs["album"].showModal();
            },
            editSection(id) {
                this.$refs["album"].showModal(id);
            },

            deleteSection(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            homePageSectionService
                                .delete(id)
                                .then(response => {
                                    this.getSections();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            checkMove: function (evt) {
                console.log(evt)
                let futureIndex = (parseInt(evt.draggedContext.futureIndex) + 1);
                let item = evt.draggedContext.element;
                let itemId = item.id;
                this.sort(itemId, futureIndex)
            },
            sort(id, index) {
                homePageSectionService.sort(id, index).then((res) => {
                    this.$snotify.success("Sorted");
                    this.getSections()
                }).catch(err => {
                    this.$snotify.success("Sorted Error");
                })
            },
            detail(id) {
                this.$router.push({name: "home-page-content", params: {id: id}});
            }
        }
    };
</script>
<style scoped>
    .buttons {
        margin-top: 35px;
    }
</style>
